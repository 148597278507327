<!-- 道路测速 -- 测速记录 -->
<template>
  <div id="velocityMeasurement-record" v-loading="loading" :element-loading-text="$t('velocityMeasurement.a1')">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-PageTitle>{{ $t('velocityMeasurement.a7') }}</my-PageTitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="16" type="flex" align="middle">
          <!-- 测速点 -->
          <a-col :span="4">
            <a-select v-model="channelValue" allowClear :placeholder="$t('velocityMeasurement.a8')" style="width: 100%">
              <a-select-option v-for="(item,index) in channelList" :key="index" :value="item.channelCode">
                {{ item.channelName }}
              </a-select-option>
            </a-select>
          </a-col>
          <!-- 车牌号码 -->
          <a-col :span="3">
            <a-input :placeholder="$t('velocityMeasurement.a9')" allowClear v-model="plateNo"/>
          </a-col>
          <!-- 初始日期时间/终止日期时间 -->
          <a-col :span="9">
            <a-input-group compact>
              <!-- 初始日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="startDate" :disabled-date="disabledStartDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" @change="handleCalendarChange" style="width: 100%"/>
              </div>
              <!-- 过渡线 -->
              <div style=" width: 10%">
                <a-input style=" pointer-events: none; backgroundColor: #fff;text-align: center;padding:0px" placeholder="~"/>
              </div>
              <!-- 终止日期 -->
              <div style=" width: 45%">
                <a-date-picker v-model="endDate" :disabled-date="disabledEndDate" show-time :allowClear="false" format="YYYY-MM-DD HH:mm:ss" style="width: 100%"/>
              </div>
            </a-input-group>
          </a-col>
          <!-- 车速 -->
          <a-col :span="6">
            <div class="speed">
              <div class="speedName">{{ $t('velocityMeasurement.a10') }}</div>
              <div class="speedSlider">
                <a-slider :min="0" :max="220" range v-model="speed" />
              </div>
            </div>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="query"> {{ $t('velocityMeasurement.a11') }} </a-button>
          </a-col>
        </a-row>
      </div>
    </div>

    <!-- 内容区域 -->
    <div class="main" v-table-size="tableSize">
      <my-tabletitle>
        {{ $t('velocityMeasurement.a38') }}
        <template v-slot:name>
          <!-- CSV导出 -->
          <a-button type="primary" @click="openOverspeedSetting"> {{ $t('velocityMeasurement.a39') }} </a-button>
        </template>
      </my-tabletitle>
      <a-table :columns="columns" :data-source="passingRecordData" :pagination="pagination" :rowClassName="rowClassName" :scroll="size" :rowKey="(record) => record.id">
        <template slot="carImgUrl" slot-scope="text, record">
          <img class="iamge" :src="iamgeUrl(record)" />
        </template>
        <template slot="details" slot-scope="text, record">
          <a-icon type="file-image" :style="{ color: '#7682ce' ,'font-size':'24px'}" @click="openDetails(record)"/>
        </template>
      </a-table>
    </div>

    <!-- 详情 -->
    <a-modal :title="$t('velocityMeasurement.a19')" :width="900" centered v-model="visible" :destroyOnClose="true" @cancel="detailsCancel" v-drag>
      <template slot="footer">
        <a-button key="back" @click="detailsCancel">{{ $t('velocityMeasurement.a5') }}</a-button>
      </template>
      <div class="detailsContainer">
        <div class="detailsImg">
          <el-image style="width: 100%; height: 100%" :src="detailsIamgeUrl" :preview-src-list="srcList"></el-image>
        </div>
        <ul class="detailsList">
          <li>{{ $t('velocityMeasurement.a12') }}{{ details.carNum }}</li>
          <li>{{ $t('velocityMeasurement.a13') }}{{ details.carNumTypeStr }}</li>
          <li>{{ $t('velocityMeasurement.a14') }}{{ details.carColorStr }}</li>
          <li>{{ $t('velocityMeasurement.a15') }}{{ details.devChnname }}</li>
          <li>{{ $t('velocityMeasurement.a16') }}{{ details.capDateStr }}</li>
          <li>{{ $t('velocityMeasurement.a17') }}{{ details.carSpeed }}km/h</li>
          <li>{{ $t('velocityMeasurement.a18') }}{{ details.carTypeStr }}</li>
        </ul>
      </div>
    </a-modal>

    <!-- 超速速度设置 -->
    <a-modal :title="$t('velocityMeasurement.a39')" :width="300" centered v-model="overspeedSettingVisible" :destroyOnClose="true" @cancel="detailsCancel" v-drag>
      <template slot="footer">
        <a-button key="back" @click="overspeedSettingCancel">{{ $t('velocityMeasurement.a5') }}</a-button>
        <a-button key="submit" type="primary" @click="overspeedSetting">{{ $t('velocityMeasurement.a40') }}</a-button>
      </template>
      <div>
        <div class="overspeedSpeed_title">{{ $t('velocityMeasurement.a41') }}</div>
        <a-input-number v-model="overspeedSpeed" :min="0" style="width: 120px;margin-right:10px"/>
        <span>Km/h</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getDeviceList, queryOfPassingRecords, getPassingTotal } from '../../api/velocityMeasurement'
import PageTitle from '../../components/Title/PageTitle.vue'
import dateContainer from '../../components/Title/dateContainer.vue'
import tabletitle from '../../components/Title/tabletitle.vue'
import moment from 'moment'

export default {
  components:{
    'my-PageTitle': PageTitle,
    'my-date': dateContainer,
    'my-tabletitle': tabletitle
  },
  data(){
    return{
      loading: false,
      passingRecordData: [],
      channelList: [],
      channelValue: undefined,
      plateNo: '',
      size: { y: 240 },
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      visible: false,
      details: {},
      detailsIamgeUrl: undefined,
      srcList: [],
      speed: [0,220],
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示10条数据
        showTotal: (total) => ` ${total} ${this.$t('velocityMeasurement.a20')}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = pageSize;
          this.query()
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current;
          this.pagination.pageSize = size;
          this.query()
        },
      },
      columns: [
        {
          title: 'NO.',
          dataIndex: 'NO',
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align: "center",
        },
        {
          title: this.$t('velocityMeasurement.a21'),
          dataIndex: "carImgUrl",
          scopedSlots: { customRender: "carImgUrl" },
          align: "center"
        },
        {
          title: this.$t('velocityMeasurement.a22'),
          dataIndex: 'capDateStr',
          align: "center"
        },
        {
          title: this.$t('velocityMeasurement.a23'),
          dataIndex: 'carTypeStr',
          align: "center"
        },
        {
          title: this.$t('velocityMeasurement.a24'),
          dataIndex: 'carDirect',
          customRender: (text, record, index)=> {
            const carDirect = record.carDirect
            switch(carDirect){
              case '0':
                return this.$t('velocityMeasurement.a25')
                break
              case '1':
                return this.$t('velocityMeasurement.a26')
                break
              case '2':
                return this.$t('velocityMeasurement.a27')
                break
              case '3':
                return this.$t('velocityMeasurement.a28')
                break
              case '4':
                return this.$t('velocityMeasurement.a29')
                break
              case '5':
                return this.$t('velocityMeasurement.a30')
                break
              case '6':
                return this.$t('velocityMeasurement.a31')
                break
              case '7':
                return this.$t('velocityMeasurement.a32')
                break
              default:
                return '--'
            }
          },
          align: "center"
        },
        {
          title: this.$t('velocityMeasurement.a33'),
          dataIndex: 'devChnname',
          align: "center"
        },
        {
          title: this.$t('velocityMeasurement.a34'),
          dataIndex: 'carSpeed',
          align: "center"
        },
        {
          title: this.$t('velocityMeasurement.a35'),
          dataIndex: 'carNum',
          align: "center"
        },
        {
          title: this.$t('velocityMeasurement.a36'),
          dataIndex: 'details',
          scopedSlots: { customRender: "details" },
          align: "center"
        }
      ],
      overspeedSettingVisible: false,
      overspeedSpeed: localStorage.getItem('overspeedSpeed') || 20,
      overspeedSpeedCopy: localStorage.getItem('overspeedSpeed') || 20
    }
  },
  watch:{

  },
  // 获取测速点列表
  mounted(){
    this.loading = true
    const data = { pageNum: 1, pageSize: 1000 }
    getDeviceList(data)
    .then(res => {
      // console.log(res)
      const { data: { pageData } } = res
      this.channelList =  pageData
      this.loading = false
    })
    .catch(err=>{
      console.log(err)
      this.loading = false
    })
  },
  methods:{
    // 表格超速行字体使用红色
    rowClassName(record, index){
      const carSpeed = record.carSpeed
      const overspeedSpeed = this.overspeedSpeedCopy
      if(carSpeed >= overspeedSpeed){
        return 'red'
      }
    },
    // 图片路径截取
    iamgeUrl(value){
      const originalString = value.carImgUrl
      const startIndex = originalString.indexOf("/d")
      const newString = 'https://10.96.143.28' + originalString.substring(startIndex)
      return newString
    },
    moment,
    // 表格高度自适应
     tableSize() {
      const indexDom = document.getElementById("velocityMeasurement-record")
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main")[0].clientHeight
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight
        const tableHeight = height - (64 + 52.5) - title
        this.size.y = tableHeight
      }
    },
    // 获取过车总数和查询过车记录列表
    query(){
      this.loading = true
      const page = this.pagination.defaultCurrent
      const rows = this.pagination.pageSize
      const startDate = this.startDate.unix()
      const endDate = this.endDate.unix()
      const plateNo = this.plateNo
      const channelIds = this.channelValue === undefined ? [] : [ this.channelValue ]
      const minSpeed = this.speed[0]
      const maxSpeed = this.speed[1]
      const autoCount = 1
      const data = { data: { page, rows, startDate, endDate, plateNo, channelIds, minSpeed, maxSpeed, autoCount } }
      // console.log(data)
      getPassingTotal(data)
      .then(res => {
        // console.log(res)
        const total = res.data
        this.pagination.total = total
        queryOfPassingRecords(data)
        .then(res=>{
          // console.log(res)
          const { data: { list } } = res
          this.passingRecordData = list
          this.loading = false
        })
        .catch(err=>{
          console.log(err)
          this.loading = false
        })
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 打开记录详情对话框
    openDetails(value){ 
      this.details = value
      this.detailsIamgeUrl = this.iamgeUrl(value)
      this.srcList = [ this.iamgeUrl(value) ]
      this.visible = true
    },
    // 关闭记录详情对话框
    detailsCancel(){
      this.visible = false
    },
    // 打开超速速度设置对话框
    openOverspeedSetting(){
      this.overspeedSettingVisible = true;
    },
    // 关闭超速速度设置对话框
    overspeedSettingCancel(){
      this.overspeedSettingVisible = false;
    },
    // 设置超速速度
    overspeedSetting(){
      const overspeedSpeed = this.overspeedSpeed;
      localStorage.setItem('overspeedSpeed', overspeedSpeed);
      this.$message.success('设置成功');
      this.overspeedSpeedCopy = overspeedSpeed;
    },
    // 开始日期限制选择范围（未来日期不可选）
    disabledStartDate(startValue) {
      // 获取计算机当前日期
      const currentDateValue = moment()
      if (!startValue) { return false }
      // 禁用大于计算机日期的日期值
      return startValue > currentDateValue
    },
    // 终止日期/时间的日期不可选范围
    disabledEndDate(endValue) {
      const startDateValue = this.startDate
      const maxDateValue = moment(startDateValue._d).add(1, "months")
      const currentDateValue = moment().endOf("day")
      if (!endValue) { return false }
      return ( startDateValue > endValue || endValue > currentDateValue || maxDateValue < endValue)
    },
    // 初始日期/时间选择变化回调
    handleCalendarChange(value, mode) {
      const currentDateValue = moment()
      const startDateValue = moment(value._d)
      const endDateValue = this.endDate
      const maxDateValue = moment(value._d).add(1, 'months')
      // 判断初始日期时间是否空值
      if(value){
        if(endDateValue > maxDateValue){
          this.endDate = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
        }else if(startDateValue > endDateValue){
          if(currentDateValue > maxDateValue){
            this.endDate = moment(value._d).add(1, 'months').subtract(1, 'day').endOf('day')
          }else{
            this.endDate = moment().endOf('day')
          }
        }
      }
    },
  }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#velocityMeasurement-record{
  width: 100%;
  height: 100%;
}
.header {
  height: 113px;
  overflow: hidden;
}
.query{
  padding: 15px 20px;
}
.main {
  height: calc(100% - 113px);
  width: 100%;
  padding: 0px 20px;
  overflow: hidden;
}
.iamge{
  width: 35px;
  height: 35px;
}
.detailsContainer{
  display: flex;
}
.detailsImg{
  width: 550px;
  height: 300px;
  margin-right: 15px;
}
.detailsList{
  list-style: none;
  font-size: 18px;
}
.detailsList li{
  margin-bottom: 10px;
}
.speed{
  display: flex;
  align-items: center;
  width: 100%;
}
.speedName{
  width: 50px;
  font-size: 14px;
  color: rgba(12, 12, 12, 0.65);
}
.speedSlider{
  width: calc(100% - 50px);
}
.overspeedSpeed_title{
  margin-bottom: 10px;
}
div /deep/.red {
  color: red;
}
</style>